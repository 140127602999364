.wrapper {
  min-height: 90vh;

  padding: 0 40px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.title span {
  font-size: 24px;
  font-weight: 500;
  color: #000;
}

.title {
  margin: 30px 0 20px;
}

.wrapper .resetPassword {
  max-width: 700px;
  width: 100%;
  min-height: 398px;
  background-color: #fff;
  border: 1px solid #d7d7d7;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.resetPasswordForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  margin-bottom: 40px;
}

.inputBlock {
  display: flex;
  flex-direction: column;

  max-width: max-content;
  margin: 0 auto;
  position: relative;
}

.inputBlock label {
  font-size: 16px;
  font-weight: 500;
  padding-left: 16px;
  margin-bottom: 4px;
}

.inputBlock input {
  min-width: 326px;
  min-height: 56px;
  margin: 0;
  padding: 0;
  border: 1px solid #e4e4e4;
  border-radius: 8px;

  font-size: 16px;
  font-weight: 400;
  padding: 0 17px;
  letter-spacing: 0.15px;
}

.inputBlock input::-webkit-input-placeholder {
  color: #bababa;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
  font-size: 16px;
}

.resetPasswordBtnWrapper {
  margin-top: 30px;

  text-align: center;
}

.resetPasswordBtnWrapper .resetPasswordBtn {
  min-width: 156px;
  min-height: 36px;
  border-radius: 8px;
  border: 1px solid #10533A;
  background-color: #10533A;
  cursor: pointer;
  color: #ffffff;

  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.165px;
}

.resetPasswordBtnWrapper .resetPasswordBtn:disabled {
  cursor: default;
}

.noProfileWrapper {
  margin-top: 16px;

  font-size: 14px;
}

.resetPasswordWrapper {
  margin-top: 8px;

  font-size: 14px;
}

.noProfile {
  font-weight: 400;
  color: #787878;
  margin-right: 4px;
}

.noProfileLink {
  font-weight: 500;
  cursor: pointer;
  text-decoration: none;
  color: #000;
}

.noProfileLink:hover {
  text-decoration: underline;
}

.noProfileLinkDisabled {
  font-weight: 500;
  cursor: default;
  text-decoration: none;
  color: #000;

  opacity: 0.6;
}

.inputBlock:nth-child(1),
.inputBlock:nth-child(2) {
  margin-top: 8px;
  position: relative;
}

.inputBlock .typeChangeBtn {
  position: absolute;
  top: 55%;
  right: 16px;
  color: #bababa;

  cursor: pointer;
}

.inputBlock .typeChangeBtn svg {
  min-width: 22px;
}

.inputBlock input:focus {
  outline: none;
}

.passValidateWrapper {
  position: absolute;
  color: #ff6464;
  max-width: 362px;
  margin: 2px auto;
  font-size: 14px;
  font-weight: 400;
  background-color: #fff;
  z-index: 999;
  right: -140px;
  top: -130px;
  padding: 10px;
  border-radius: 8px;
  cursor: default;

  border: 1px solid #e4e4e4;
}

.passValidateWrapper ul {
  list-style-type: disc;
  margin: 2px 0;
  padding-left: 17px;
}

.info {
  max-width: 360px;
  text-align: center;
  font-weight: 400;
  margin: 2px auto 0;
  max-width: 330px;
  font-size: 10px;
  color: #000;
  line-height: 18px;
  letter-spacing: -0.165px;

  opacity: 0.7;
}

@media (max-width: 700px) {
  .passValidateWrapper {
    position: static;
  }
}

@media (max-width: 520px) {
  .inputBlock input,
  .info {
    min-width: 290px;
  }
}

@media (max-width: 450px) {
  .title span {
    font-size: 22px;
  }
  .inputBlock label {
    font-size: 14px;
  }
  .inputBlock input,
  .info {
    min-width: 260px;
    max-width: 260px;
    min-height: 46px;
  }
}

@media (max-width: 410px) {
  .inputBlock input {
    min-width: 230px;
  }
  .noProfileWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
  }
}
