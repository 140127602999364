.wrapper {
  position: relative;
  height: 83px;
  background-color: #fff;
  border-bottom: 1px solid #efefef;
  display: flex;
  justify-content: end;
}

.wrapper .allConeWrapper {
  margin-right: 20px;
  display: flex;
  align-items: center;
}

.wrapper .allConeWrapper .allCone {
  border: 1px solid #efefef;
  border-radius: 4px;
  min-height: 50px;
  min-width: 82px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
}

.wrapper .allConeWrapper .allCone span:nth-child(1) {
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: -0.165px;
}

.wrapper .allConeWrapper .allCone span:nth-child(2) {
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  color: #bfbfbf;
}

@media (max-width: 1080px) {
  .wrapper .allConeWrapper {
    display: none;
  }

  .total {
    max-height: 40px;
    max-width: 40px;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translate(0, -50%);

    cursor: pointer;
  }

  .totalContentWrapper {
    position: absolute;
    top: 84px;
    width: 100%;
    padding: 10px 0;
    border: 1px solid #e4e4e4;
    border-top: none;
    background-color: #fff;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 3000;
  }

  .totalContent {
    position: relative;
    border: 1px solid #bebdbd;
    min-height: 71px;
    min-width: 318px;
    border-radius: 8px;
    margin: 10px 0 !important;
    display: flex;
    flex-direction: column;
  }

  .totalContent span:nth-child(1) {
    font-weight: 700;
    font-size: 24px;
    color: #323232;
    margin: 10px 10px 0 10px;
  }
  .totalContent span:nth-child(2) {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.25px;
    color: #bebdbd;
    margin-left: 10px;
  }

  .blur {
    min-height: 100vh;
    min-width: 100vw;
    position: fixed;
    top: 84px;
    z-index: 2100;
    backdrop-filter: blur(3px);
  }
}

@media (max-width: 700px) {
  .wrapper {
    height: 59px;
  }

  .total {
    right: 12px;
  }

  .totalContentWrapper {
    top: 60px;
    padding: 20px 0;
  }

  .blur {
    top: 60px;
  }
}
