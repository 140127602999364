.wrapper {
  margin-left: 60px;
  background-color: #f8f8f8;

  max-width: 100%;
  overflow-x: auto;

  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}

.wrapperFull {
  margin-left: 260px;
}

.wrapper h2 {
  margin: 31px 0 5px 40px;

  font-size: 24px;
  line-height: 24px;
  letter-spacing: 0.25px;
}

.table {
  margin: 40px 100px 0 40px;
  max-height: max-content;
  max-width: 844px;
  min-width: 836px;
}

.table .header {
  max-height: 20px;
  display: flex;
  /* width: 836px;  */
  width: 100%;
}

.table .header div:nth-child(1) svg {
  position: absolute;
  top: 1px;
  right: -19px;
  font-size: 16px;
  color: #bababa;
}

.table .header div:nth-child(1) {
  margin-left: 16px;
  min-width: 76px;
}

.table .header div:nth-child(2) {
  margin-left: 20px;
  min-width: 92px;
  /* margin-left: 13.61%; */
}

.table .header div:nth-child(3) {
  margin-left: 22px;
  min-width: 148px;
  /* margin-left: 11.7%; */
}
.table .header div:nth-child(4) {
  margin-left: 18px;
  min-width: 150px;
  /* margin-left: 10%; */
}
.table .header div:nth-child(5) {
  margin-left: 18px;
  min-width: 148px;
  /* margin-left: 7.3%; */
}
.table .header div:nth-child(6) {
  margin-left: 20px;
  min-width: 92px;
  /* margin-left: 9.4%; */
}

.table .header div {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: #757575;
}

.table .row {
  position: relative;
  background-color: #fff;
  min-height: 41px;
  max-height: 41px;

  margin: 8px 0;
  border-radius: 8px;
  display: flex;
  align-items: center;

  color: #323232;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.table .row img {
  max-width: 24px;
  max-height: 24px;
  position: absolute;
  right: 2px;
}

.table .row .info {
  min-width: 24px;
  min-height: 24px;
  position: relative;
}

.table .row .info:hover::after {
  content: attr(data-info);

  position: absolute;
  top: -116px;
  right: -54px;

  min-width: 176px;
  min-height: 80px;
  max-height: 80px;

  background-color: #fff;
  border-radius: 8px;
  padding: 10px;
  z-index: 90;

  -webkit-box-shadow: 0px 7px 15px -6px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 7px 15px -6px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 7px 15px -6px rgba(0, 0, 0, 0.5);
}

.table .row .info:hover::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 20px;
  border: 10px solid transparent;
  z-index: 99;
  border-top: 10px solid #fff;
}

.table .row div {
  text-align: left;
  /* position: relative; */
  cursor: default;
}

.table .row div[data-title]:hover::after {
  content: attr(data-title);
  position: absolute;
  top: 70%;
  z-index: 10;
  padding: 5px 10px;
  background: rgba(16, 83, 58, 0.9);
  color: #ffffff;
  border-radius: 8px;
}

.table .row div:nth-child(1) {
  margin-left: 16px;
  /* flex: 1; */
  /* flex: 1 40px; */
  /* max-width: 100px; */
  /* margin-left: 14px; */
}

.table .row div:nth-child(2) {
  margin-left: 26px;
  min-width: 92px;
  /* flex: 1; */
  /* flex: 1 40px; */
}

.table .row div:nth-child(3) {
  margin-left: 20px;
  min-width: 96px;
  /* flex: 1; */
  /* flex: 1 50px; */
}

.table .row div:nth-child(4) {
  margin-left: 72px;
  min-width: 150px;
  /* flex: 1; */
  /* flex: 1 40px; */
}

.table .row div:nth-child(5) {
  margin-left: 18px;
  min-width: 148px;
  /* flex: 1; */
  /* flex: 1 40px; */
  /* text-align: center; */
}

.table .row div:nth-child(6) {
  margin-left: 20px;
  min-width: 86px;
  /* flex: 1; */
  /* flex: 1 30px; */
  /* text-align: center; */
}

.table .pages {
  margin: 30px auto;
  width: 100%;
  display: flex;
  justify-content: center;

  list-style-type: none;
}

.table .pages li {
  margin: 4px;
}

.table .pages .step {
  color: #323232;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.8px;
  margin: 0 25px;
  text-transform: uppercase;
  cursor: pointer;
}

.table .pages .step[aria-disabled="true"] {
  cursor: default;
  color: #bebdbd;
}

.pages .pageNum {
  cursor: pointer;
  color: #bebdbd;
}

.break {
  cursor: pointer;
  color: #bebdbd;
}

.selected {
  color: #323232 !important;
  transform: scale(1.15, 1.15);
}

.noData {
  margin: 80px auto 20px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 316px;
}

.noData h4 {
  margin: 16px 0 8px;
  color: #323232;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.noData span {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #757575;
}

.errorPopupWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;

  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(3px);

  z-index: 9999;
}

.errorPopupWrapper .errorPopup {
  margin-left: 0 !important;
  padding: 60px;
  position: relative;
  max-width: 240px;
  width: 100%;
  min-height: 150px;
  max-height: max-content;
  border-radius: 8px;
  background-color: #fff;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #757575;

  -webkit-box-shadow: 0px 0px 17px -1px rgba(0, 0, 0, 0.61);
  -moz-box-shadow: 0px 0px 17px -1px rgba(0, 0, 0, 0.61);
  box-shadow: 0px 0px 17px -1px rgba(0, 0, 0, 0.61);
}

.errorPopupWrapper .errorPopup .closeBtn {
  position: absolute;
  right: 14px;
  top: 14px;
  padding: 0;
  border: none;
  background-color: inherit;
  cursor: pointer;
}

.closeBtn svg {
  min-height: 24px;
  min-width: 24px;

  opacity: 0.54;
}

@media (max-width: 860px) and (min-width: 700px) {
  .noDataFull {
    margin: 80px auto 20px 40%;
  }
}

@media (max-width: 700px) {
  .wrapper {
    margin: 0 auto;
  }

  .wrapper h2 {
    margin: 31px 0 0 20px;
  }

  .errorPopupWrapper .errorPopup {
    margin: 0 20px !important;
    padding: 40px;
  }
}
