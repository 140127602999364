.wrapper {
  margin-left: 60px;

  background-color: #f8f8f8;
  padding: 0 0 20px 0;

  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}

.wrapperFull {
  margin-left: 260px;
}

.wrapper .walletHeader {
  display: flex;
}

.wrapper .walletHeader h2 {
  width: max-content;
  font-weight: 700;
  font-size: 24px;
  line-height: 20px;
  letter-spacing: 0.25px;

  margin: 31px 0 0 40px;
}

.wrapper .walletHeader .btnGroup {
  margin-left: auto;
}

.wrapper .walletHeader .addWalletBtn {
  max-width: 186px;
  width: 186px;
  min-height: 36px;
  border-radius: 8px;
  border: 1px solid #10533A;
  background-color: #10533A;
  cursor: pointer;
  margin: 23px 20px 0 0;
  color: #ffffff;

  font-size: 16px;
  font-weight: 500;
}

.walletContent .regSucces {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 146px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}

.walletContent .regSucces span {
  margin-top: 16px;
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
}

.allWalletsWrapper {
  max-width: max-content;
  margin: 26px 38px;

  display: flex;
  flex-wrap: wrap;
  gap: 24px;
}

.wrapper .more {
  border: 1px solid #10533A;
  border-radius: 8px;
  margin-left: 38px;
  padding: 6px 10px;
  cursor: pointer;
  max-width: max-content;

  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: -0.165px;
}

@media (max-width: 970px) {
  .walletContent .regSuccesFull {
    margin-left: 35%;
  }
}

@media (max-width: 900px) {
  .wrapper .walletHeader {
    display: flex;
    flex-direction: column;
  }

  .wrapper .walletHeader .btnGroup {
    margin-left: 40px;
  }
}

@media (max-width: 700px) {
  .wrapper {
    margin-left: 0;
  }

  .wrapper .walletHeader h2 {
    margin-left: 20px;
  }

  .wrapper .walletHeader .addWalletBtn {
    font-weight: bold;
  }

  .walletContent .regSucces {
    margin-right: 20px;
    margin-left: 20px;
    text-align: center;
  }

  .wrapper .walletHeader .btnGroup {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 100%;
    align-items: center;
    border-top: 1px solid #e4e4e4;
    background: #fff;
    z-index: 990;
  }
  .wrapper .walletHeader .btnGroup button {
    min-height: 50px;
    min-width: 320px;
    width: calc(100% - 40px);
    max-width: 100%;
    margin-right: 20px !important;
    margin-left: 20px !important;
  }

  .wrapper .walletHeader .btnGroup button[data-added="true"] {
    display: none;
  }

  .wrapper .walletHeader .btnGroup button:nth-child(2) {
    margin-bottom: 20px !important;
  }

  .allWalletsWrapper {
    justify-content: center;
    margin: 26px 38px 175px 38px;
  }

  .wrapper .more {
    margin: -145px auto 175px auto;
    padding: 6px 175px;
  }
}

@media (max-width: 420px) {
  .wrapper .more {
    padding: 6px 145px;
  }
}

@media screen and (max-height: 660px){
  .walletsFixed {
    position: fixed;
  }

  .moreFixed {
    display: none;
  }
}

@media screen and (max-height: 660px) and (max-width: 460px){
  .walletsFixed {
    margin: 26px auto;
  }
}
