.walletWrapperCone {
  max-width: 380px;
  min-width: 380px;
  min-height: 146px;
  border-radius: 8px;
  background: linear-gradient(278.4deg, #b4b3b1 0%, #e4e4e4 100%);

  display: flex;
  flex-direction: column;
}

.walletWrapperBtc {
  max-width: 380px;
  min-width: 380px;
  min-height: 146px;
  border-radius: 8px;
  background: linear-gradient(278.4deg, #10533A 0%, #157452 100%);
  color: #ffffff;

  display: flex;
  flex-direction: column;
}

.walletWrapperBtc .confirmDeleteWrapper {
  color: #323232;
}

.header {
  margin: 20px 20px 0 20px;
  display: flex;
  align-items: center;
}

.header img {
  max-width: 24px;
  max-width: 24px;
}

.header span {
  margin-left: 8px;
  font-weight: 500;
  line-height: 25px;
  font-size: 24px;
  letter-spacing: 0.25px;
}

.header span[data-maxwidth="true"] {
  max-width: 229px;
  position: relative;
  overflow: hidden;
}

.header span[data-maxwidth="true"]:after {
  content: "";
  text-align: right;
  position: absolute;
  top: 2px;
  right: -10px;
  width: 35%;
  height: 1em;
  background: linear-gradient(to right, rgba(199, 198, 197, 0), #c1c1bf 90%);
  pointer-events: none;
}

.header span[data-maxwidth="true"][data-currency="BTC"]:after {
  background: linear-gradient(to right, rgba(251, 229, 151, 0), #10533A 90%);
}

.header .delete {
  margin-left: auto;
  cursor: pointer;
}

.header .delete img {
  max-width: 20px;
  max-height: 20px;
}

.address {
  margin-top: 40px;
}

.address span:nth-child(1) {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.25px;
  margin-left: 20px;
}

.address span:nth-child(2) {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: #757575;
  margin-left: 4px;
}

.footer {
  margin: 2px 10px 0 20px;
  overflow: hidden;
}

.confirmDeleteWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;

  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(3px);

  z-index: 9999;
}

.confirmDeleteWrapper .confirmDeleteContent {
  position: relative;
  max-width: 800px;
  width: 100%;
  min-height: 210px;
  margin: 0 20px;
  height: max-content;
  border-radius: 8px;
  background-color: #fff;

  -webkit-box-shadow: 0px 0px 17px -1px rgba(0, 0, 0, 0.61);
  -moz-box-shadow: 0px 0px 17px -1px rgba(0, 0, 0, 0.61);
  box-shadow: 0px 0px 17px -1px rgba(0, 0, 0, 0.61);

  display: flex;
  flex-direction: column;
  align-items: center;
}

.closeBtn {
  position: absolute;
  right: 20px;
  top: 20px;
  padding: 0;
  border: none;
  background-color: inherit;
  cursor: pointer;
}

.confirmDeleteWrapper .confirmDeleteContent .title {
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  margin-top: 30px;
}

.confirmDeleteWrapper .confirmDeleteContent .content {
  margin: 40px 20px 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.25px;
  text-align: center;
}

.confirmDeleteWrapper .confirmDeleteContent .footer {
  margin-top: 20px;
}

.confirmDeleteWrapper .confirmDeleteContent .footer button {
  width: 154px;
  height: 34px;
  margin: 0 6px;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: -0.165px;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid #10533A;
}

.confirmDeleteWrapper .confirmDeleteContent .footer button:nth-child(1) {
  background-color: #fff;
  color: #323232;
}

.confirmDeleteWrapper .confirmDeleteContent .footer button:nth-child(2) {
  background-color: #10533A;
  color: #ffffff;
}

.footer {
  position: relative;
}

.footer span:after {
  content: "";
  text-align: right;
  position: absolute;
  top: 3px;
  right: 0px;
  width: 10%;
  height: 1em;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), #b4b3b1 90%);
  pointer-events: none;
}

.footer span[data-coin="BTC"]:after {
  background: linear-gradient(to right, rgba(255, 255, 255, 0), #10533A 90%);
}

@media (max-width: 420px) {
  .confirmDeleteWrapper .confirmDeleteContent {
    max-width: 320px;
  }

  .confirmDeleteWrapper .confirmDeleteContent .content {
    max-width: 288px;
    max-height: max-content;
    word-wrap: break-word;
  }

  .confirmDeleteWrapper .confirmDeleteContent .footer {
    display: flex;
    margin-bottom: 16px;
  }

  .confirmDeleteWrapper .confirmDeleteContent .footer button {
    width: 134px;
    height: 50px;
  }

  .walletWrapperBtc,
  .walletWrapperCone {
    max-width: 320px;
    min-width: 320px;
  }
}
