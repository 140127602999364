.wrapper {
  margin-left: 60px;

  background-color: #f8f8f8;

  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}

.wrapperFull {
  margin-left: 260px;
}

.wrapper .content {
  margin-left: 40px;
}

.wrapper .content h2 {
  margin: 31px 0;
  font-size: 24px;
  line-height: 20px;
  letter-spacing: 0.25px;
}

.wrapper .content .lang {
  display: flex;
  flex-direction: column;
  width: max-content;
  position: relative;
}

.wrapper .content .lang .langLabel {
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  margin-bottom: 20px;
}

.wrapper .content .lang .select {
  min-width: 116px;
  min-height: 55px;
  border: 1px solid #e4e4e4;
  border-radius: 8px;
  background-color: #fff;

  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #323232;
  border: 1px solid #e4e4e4;

  display: flex;
}

.wrapper .content .lang .select div:nth-child(1) {
  min-width: 68px;
  display: flex;
}

.wrapper .content .lang .select div:nth-child(1) span {
  margin: 14px 0 0 17px;
}

.wrapper .content .lang .select div:nth-child(2) {
  border-left: 1px solid #e4e4e4;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.wrapper .content .lang .select div:nth-child(2) svg {
  color: #bababa;
}

.lang .dropDown {
  border: 1px solid #e4e4e4;
  border-radius: 8px;
  min-height: 53px;
  min-width: 116px;
  background-color: #fff;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #323232;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);

  display: flex;
  align-items: center;
  cursor: pointer;

  position: absolute;
  bottom: -57px;
  z-index: 100;
}

.lang .dropDown span {
  margin-left: 17px;
}

.content .authBlock {
  display: flex;
}

.content .auth {
  margin-top: 40px;
  display: flex;
  align-items: center;
}

.authBlock .switch {
  position: relative;
  display: inline-block;
  width: 52px;
  height: 28px;
}

.authBlock .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.authBlock .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  -webkit-transition: 0.4s;
  transition: 0.4s;

  border: 1px solid #bebdbd;
}

.authBlock .slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 3px;
  bottom: 3px;
  background-color: #bebdbd;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.authBlock input:checked + .slider {
  background-color: #10533A;
  border: 1px solid #10533A;
}

.authBlock input:checked + .slider:before {
  -webkit-transform: translateX(24px);
  -ms-transform: translateX(24px);
  transform: translateX(24px);

  background-color: #fff;
}

.authBlock .slider.round {
  border-radius: 22px;
}
.authBlock .slider.round:before {
  border-radius: 50%;
}

.auth .authTitle {
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  margin-bottom: 6px;

  margin-left: 20px;
}

.footer {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
}

.footer button {
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: -0.165px;
  border-radius: 8px;
  cursor: pointer;

  min-height: 50px !important;
  min-width: 204px !important;
}

.footer button:nth-child(1) {
  max-width: 154px;
  min-height: 34px;
  border: 1px solid #10533A;
  background-color: #fff;
  color: #323232;
  font-size: 16px;
  line-height: 18px;

  font-weight: bold;
}

.twoFactAuthWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  min-height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;

  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(3px);

  z-index: 9999;
}

.twoFactAuthWrapper .twoFactAuthContent {
  position: relative;
  max-width: 505px;
  width: 100%;
  min-height: 210px;
  height: max-content;
  border-radius: 8px;
  background-color: #fff;

  -webkit-box-shadow: 0px 0px 17px -1px rgba(0, 0, 0, 0.61);
  -moz-box-shadow: 0px 0px 17px -1px rgba(0, 0, 0, 0.61);
  box-shadow: 0px 0px 17px -1px rgba(0, 0, 0, 0.61);

  display: flex;
  flex-direction: column;
  align-items: center;
}

.closeBtn {
  position: absolute;
  right: 20px;
  top: 20px;
  padding: 0;
  border: none;
  background-color: inherit;
  cursor: pointer;
}

.closeBtn svg {
  min-height: 24px;
  min-width: 24px;

  opacity: 0.54;
}

.twoFactAuthWrapper .twoFactAuthContent .title {
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  margin-top: 74px;
}

.twoFactAuthWrapper .twoFactAuthContent .authContent {
  margin-top: 30px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.25px;
  display: flex;
  flex-direction: column;
}

.twoFactAuthWrapper .twoFactAuthContent .textAuthContent {
  margin-top: 30px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.25px;
  text-align: center;
  color: #757575;
}

.twoFactAuthWrapper .twoFactAuthContent .footer {
  margin-top: 20px;
  margin: 30px 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  min-width: max-content;
}

.twoFactAuthWrapper .twoFactAuthContent .footer button {
  max-width: 213px;
  min-width: 213px;
  height: 50px;
  margin: 0 6px;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: -0.165px;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid #10533A;
  color: #323232;
}

.twoFactAuthWrapper .twoFactAuthContent .footer button:nth-child(1) {
  background-color: #fff;
  color: #323232;
}

.twoFactAuthWrapper .twoFactAuthContent .footer button:nth-child(2) {
  background-color: #10533A;
  color: #ffffff;
}

.authContent input {
  min-width: 326px;
  min-height: 56px;
  margin: 0;
  padding: 0;
  border: 1px solid #e4e4e4;
  border-radius: 8px;

  font-size: 16px;
  font-weight: 400;
  padding: 0 17px;
  letter-spacing: 0.15px;
}

.authContent input::-webkit-input-placeholder {
  color: #bababa;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
  font-size: 16px;
}

.authContent .changePassInput {
  margin-bottom: 12px;
}

.authContent label {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-left: 17px;
}

.authContent .inputBlock {
  display: flex;
  flex-direction: column;
  position: relative;
}

.inputBlock input:focus {
  outline: none;
}

.authContent .inputBlock .typeChangeBtn {
  position: absolute;
  top: 48%;
  right: 16px;
  color: #bababa;

  cursor: pointer;
}

.authContent .inputBlock .typeChangeBtn svg {
  min-width: 22px;
}

.twoFactAuthWrapper .twoFactAuthContent .footer .changePasswordBtn {
  border: 1px solid #10533A;
  background-color: #10533A !important;
  color: #ffffff !important;
}

.passValidateWrapper {
  position: absolute;
  color: #ff6464;
  max-width: 362px;
  margin: 2px auto;
  font-size: 14px;
  font-weight: 400;
  background-color: #fff;
  z-index: 999;
  right: -156px;
  top: -130px;
  padding: 10px;
  border-radius: 8px;
  cursor: default;

  border: 1px solid #e4e4e4;
}

.passValidateWrapper ul {
  list-style-type: disc;
  margin: 2px 0;
  padding-left: 17px;
}

@media (max-width: 700px) {
  .copyLink {
    color: #323232;
    font-weight: 600;
    text-decoration: underline;
    cursor: pointer;
    margin-top: 8px;
  }

  .wrapper {
    margin: 0 auto;
  }

  .wrapper .content {
    margin: 20px;
  }

  .footer button {
    min-width: 100% !important;
  }

  .twoFactAuthWrapper .twoFactAuthContent {
    margin: 0 20px;
  }

  .twoFactAuthWrapper .twoFactAuthContent input {
    min-width: 288px;
  }

  .twoFactAuthWrapper .twoFactAuthContent .title {
    margin-right: 16px;
    margin-left: 16px;
    text-align: center;
  }

  .authContent .footer button.authBtn {
    max-width: 134px !important;
    min-width: 134px !important;
  }
  button.twoFaBtn {
    max-width: 134px !important;
    min-width: 134px !important;
  }

  .twoFactAuthWrapper .twoFactAuthContent .textAuthContent {
    margin: 30px 16px 0;
  }

  .passValidateWrapper {
    position: relative;
    top: 0;
    right: 0;
  }
}

@media (max-width: 400px) {
  .twoFactAuthWrapper .twoFactAuthContent input {
    min-width: 240px;
  }
}

@media screen and (max-height: 560px){
  .twoFactAuthWrapper {
    position: absolute;
  }
}
